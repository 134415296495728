import Section from 'components/Section/Section'
import { A2ADataContextProvider } from 'contexts/A2ADataContext'
import Layout from 'Layout'
import { Colors } from 'commons/Theme'
import { AtomButton } from 'atoms/AtomButton'
import { ArrowRightIcon } from 'components/Icons/ArrowRightIcon'
import { AtomLink } from 'atoms/AtomLink'

export interface CTA {
  label?: string
  IconRight?: JSX.Element
  onClick?: () => void
  disabled?: boolean
  onDataLayer?: () => void
  type?: 'ghost' | 'secondary' | 'primary'
  color?: 'white' | 'blue' | 'orange'
}

const CtaShowcase = () => {
  const ctatList: Array<CTA & { testLabel: string; background: string }> = [
    {
      label: 'Salva',
      disabled: false,
      onClick: () => console.log('click'), // eslint-disable-line no-console
      testLabel: 'Primary - sfondo chiaro',
      background: 'white',
    },
    {
      label: 'Invia',
      IconRight: <ArrowRightIcon />,
      onClick: () => console.log('click'), // eslint-disable-line no-console
      testLabel: 'Primary - fondo chiaro con icona',
      disabled: false,
      onDataLayer: () => {},
      background: 'white',
    },
    {
      label: 'Cambia le tue risposte',
      IconRight: <ArrowRightIcon />,
      type: 'ghost',
      onClick: () => console.log('click'), // eslint-disable-line no-console
      color: 'blue',
      testLabel: 'Link su sfondo chiaro',
      background: 'white',
    },
    {
      label: 'Cambia le tue risposte',
      type: 'ghost',
      onClick: () => console.log('click'), // eslint-disable-line no-console
      color: 'white',
      testLabel: 'Link su sfondo scuro',
      background: 'dark',
    },
    {
      label: 'Cambia le tue risposte',
      IconRight: <ArrowRightIcon />,
      type: 'primary',
      onClick: () => console.log('click'), // eslint-disable-line no-console
      color: 'blue',
      testLabel: 'Button default',
      background: 'white',
    },
    {
      label: 'Cambia le tue risposte',
      IconRight: <ArrowRightIcon />,
      type: 'primary',
      onClick: () => console.log('click'), // eslint-disable-line no-console
      color: 'white',
      testLabel: 'Button default',
      background: 'white',
    },
    {
      label: 'Cambia le tue risposte',
      IconRight: <ArrowRightIcon />,
      type: 'secondary',
      onClick: () => console.log('click'), // eslint-disable-line no-console
      color: 'blue',
      testLabel: 'Button outline',
      background: 'white',
    },
    {
      label: 'Cambia le tue risposte',
      IconRight: <ArrowRightIcon />,
      type: 'secondary',
      onClick: () => console.log('click'), // eslint-disable-line no-console
      color: 'white',
      testLabel: 'Button outline',
      background: 'dark',
    },
  ]

  return (
    <A2ADataContextProvider>
      <Layout pageTitle="Hub Commerciale Wave 4 - Call to action">
        <Section backgroundColor={Colors.blue100} hasContainer={true}>
          <div
            style={{
              display: 'flex',
              marginBottom: '5rem',
              justifyContent: 'space-end',
              backgroundColor: '#065582',
              padding: '5rem 0rem',
            }}
          >
            <div style={{ width: '50%', paddingLeft: '1rem', color: 'white' }}>
              Atom Link - ghost color white
            </div>
            <div style={{ textAlign: 'left' }}>
              <AtomLink
                label="Vai alla homepage"
                to="/"
                styleType="ghost"
                color="white"
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              marginBottom: '5rem',
              justifyContent: 'space-end',
            }}
          >
            <div style={{ width: '50%', paddingLeft: '1rem' }}>
              Atom Link - ghost color blue
            </div>
            <div style={{ textAlign: 'left' }}>
              <AtomLink
                label="Vai alla homepage"
                to="/"
                styleType="ghost"
                color="blue"
              />
            </div>
          </div>
          {ctatList?.map(
            (
              cta: CTA & { testLabel: string; background: string },
              index: number
            ) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    marginBottom: '5rem',
                    justifyContent: 'space-end',
                    backgroundColor:
                      cta.background && cta.background === 'dark'
                        ? '#065582'
                        : 'transparent',
                    padding:
                      cta.background && cta.background === 'dark'
                        ? '5rem 0rem'
                        : 'auto',
                  }}
                >
                  <div
                    style={{
                      width: '50%',
                      paddingLeft: '1rem',
                      color:
                        cta.background && cta.background === 'dark'
                          ? '#FFFFFF'
                          : 'auto',
                    }}
                  >
                    {cta?.testLabel}
                  </div>
                  <div
                    style={{ textAlign: 'left', display: 'flex', gap: '1rem' }}
                  >
                    <AtomButton
                      label={cta?.label ?? ''}
                      icon={cta?.IconRight}
                      styleType={cta?.type}
                      onClick={() => {}}
                      disabled={false}
                      onDataLayer={cta?.onDataLayer}
                      color={cta?.color}
                    />
                    <AtomButton
                      label={cta?.label ?? ''}
                      icon={cta?.IconRight}
                      styleType={cta?.type}
                      onClick={() => {}}
                      disabled={true}
                      onDataLayer={cta?.onDataLayer}
                      color={cta?.color}
                    />
                  </div>
                </div>
              )
            }
          )}
        </Section>
      </Layout>
    </A2ADataContextProvider>
  )
}

export default CtaShowcase
